<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'Detail',
  data() {
    return {}
  },
  methods: {
    getDetail(row) {
      console.log(row)
    },
    reset(done) {
      this.isChange = false
      this.$refs.elFormDom.resetFields()
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
  }
}
</script>