<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="分类名称:" prop="parent_id">
          <el-select v-model="table.params.parent_id" filterable clearable placeholder="请选择" @change="onSearch">
            <el-option
              v-for="item in classifyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="二级名称:" prop="keyword">
          <el-input clearable v-model="table.params.keyword" placeholder="请输入分类名称" @keydown.enter.native="onSearch"></el-input>
        </el-form-item>
        <el-form-item label="下单模式:" prop="order_mode">
          <el-select v-model="table.params.order_mode" placeholder="请选择" clearable @change="onSearch">
            <el-option label="发布需求" :value="1"></el-option>
            <el-option label="购买服务" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="showEdit">添加</el-button>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:order_mode="{row}">
        <span>{{row.order_mode == 1 ? '发布需求' : '购买服务'}}</span>
      </template>
      <template v-slot:image="{row}">
        <el-image
          v-if="!!row.image"
          style="width: 141px; height: 60px; line-height: 60px;"
          :src="row.image"
          fit="cover"
          :preview-src-list="[row.image]">
          </el-image>
      </template>
      <template v-slot:status="{row}">
        <el-switch
          v-model="row.status"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="2"
          @change="handleStatus(row)">
        </el-switch>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" icon="el-icon-edit" @click="showEdit(row)">编辑</el-button>
        <el-button type="text" icon="el-icon-delete" @click="remove(row)">删除</el-button>
      </template>
    </VTable>

    <edit ref="edit" :classifyOpt="classifyArr" @refresh="getTable"></edit>
    <detail ref="detail"></detail>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue';
import Detail from './components/Detail.vue';
import { mixinTable } from '@/mixins/table.js';
export default {
  name: 'twolevelIndex',
  components: {
    VTable,
    Edit,
    Detail
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "name", label: "二级名称", hidden: false },
        { name: "parent_name", label: "所属一级", hidden: false },
        { name: "image", label: "图片", hidden: false },
        { name: "order_mode", label: "下单模式", hidden: false },
        { name: "status", label: "状态", hidden: false },
        { name: "create_time", label: "添加时间", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "140", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          keyword: '',
          parent_id:'',
          type: 2, // 级别 1:一级 2:二级
          order_mode: '', // 下单模式 1-发布需求 2-购买服务
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      classifyOpt: [],
      classifyArr: [],
    }
  },
  created() {
    this.getTable();
    this.getClassify();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/flex_kind/list', { params:this.table.params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取一级分类
    getClassify() {
      this.$http.get('/admin/flex_kind/list', { params:{page:1,count:1000,type:1}}).then(res => {
        if(res.code === 1) {
          this.classifyOpt= res.data.list;
          this.classifyArr = res.data.list.filter(item => item.status == 1)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.delete(row.id);
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
    delete(rowid) {
      this.$http.post('/admin/flex_kind/delete', { id: rowid }).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
          this.getTable();
        } else {
          this.$message.error(res.data)
        }
      })
    },
    // 切换状态
    handleStatus(row) {
      let _params = {
        id: row.id,
        status: row.status,
        name: row.name,
        sort: row.sort,
        image: row.image,
        parent_id: row.parent_id,
        order_mode: row.order_mode, // 下单模式
        is_multi: row.is_multi, // 接单模式
      }
      this.$http.post('/admin/flex_kind/edit', _params).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
          this.getTable()
        } else {
          this.$message.error(res.data)
        }
      }).finally(() => {
        this.getTable();
      })
    }
  }
}
</script>