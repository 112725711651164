<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
      size="small"
    >
      <el-form-item prop='parent_id' label='所属分类'>
        <el-select v-model="form.data.parent_id" placeholder="请选择" style="width:100%;">
          <el-option
            v-for="item in classifyOpt"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop='name' label='二级名称'>
        <el-input v-model="form.data.name" placeholder="请输入" maxlength="20" clearable></el-input>
      </el-form-item>
      <el-form-item prop="order_mode" label="下单模式">
        <el-radio-group v-model="form.data.order_mode">
          <el-radio :label="1">发布需求</el-radio>
          <el-radio :label="2">购买服务</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- 发布需求：有需求参考价和接单人数（统一默认多人） -->
      <template v-if="form.data.order_mode == 1">
        <el-form-item prop='reference_price' label='需求参考价'>
          <el-input v-model="form.data.reference_price" placeholder="请输入" clearable >
          </el-input>
        </el-form-item>
        <el-form-item prop="is_multi" label="接单人数">
          <el-radio-group v-model="form.data.is_multi">
            <el-radio :label="2">单人</el-radio>
            <el-radio :label="1">多人</el-radio>
          </el-radio-group>
        </el-form-item>
      </template>
      <el-form-item prop='image' label='上传主图'>
        <div style="font-size:12px;color:#f56c6c;">(建议尺寸：750*320像素)</div>
        <el-upload
          class="avatar-uploader-image"
          action=""
          :headers="{ token: token }"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :http-request="httpRequest">
          <img v-if="form.data.image" :src="form.data.image" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <!-- 选了购买服务模式,增加上传详情图（非必传） -->
      <el-form-item prop='detail_image' label='上传详情图'  v-if="form.data.order_mode == 2">
        <div style="font-size:12px;color:#f56c6c;">(建议高度：750像素,最多9张)</div>
        <el-upload
          action="/api/common/qiniu/uploadFile"
          :limit="9"
          list-type="picture-card"
          class="imgWrap"
          :headers="{ token: token }"
          :on-preview="handlePictureCardPreview"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :on-remove="handleRemove"
          :file-list="fileList"
          :on-exceed="handleExceed"
          accept="image/jpeg, image/png, image/gif"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>

      <el-form-item prop='sort' label='排序'>
        <el-input v-model="form.data.sort" placeholder="数字越小排序越靠前" maxlength="20" clearable @input="form.data.sort=form.data.sort.replace(/[^\d]/g,'')"></el-input>
      </el-form-item>
      <el-form-item prop='status' label='状态'>
        <el-switch
          v-model="form.data.status"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="2">
        </el-switch>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>

    <el-dialog :visible.sync="dialogVisibleUrl" append-to-body>
      <img v-if="dialogVisibleUrl" width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'TwolevelEdit',
    props:['classifyOpt'],
    data() {
      return {
        token: '',
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            parent_id: '',
            name: '',
            sort: 100,
            image: '',
            status: 1,
            reference_price: '', // 需求参考价
            is_multi: 1, //接单人数 1:多人 2:单人
            order_mode: 1,//下单模式 1-发布需求 2-购买服务
            detail_image: '', // 详情图
            high: ''  // 详情图高度
          },
          rules: {
            name: [{ required: true, message:'请输入分类名称', trigger: 'change' }],
            sort: [{ required: true, message:'请输入排序', trigger: 'change' }],
            status: [{ required: true, message:'必选项', trigger: 'change' }],
            image: [{ required: true, message: '请上传图标', trigger: 'change' }],
            is_multi: [{ required: true, message: '请选择', trigger: 'change' }],
            order_mode: [{ required: true, message: '请选择', trigger: 'change' }],
            parent_id: [{ required: true, message: '请选择一级分类', trigger: 'change' }],
          }
        },
        fileList: [],
        dialogVisibleUrl: false,
        dialogImageUrl: []
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        this.$nextTick(() => {
          common.deepClone(this.form.data, row)

          let arr = []
          if(row.detail_image_arr.length > 0 && !!row.detail_image_arr[0]) {
            row.detail_image_arr.forEach(i => {
              let str = {
                uid: '',
                url: i
              }
              arr.push(str)
            })
          }
          row.high.map((j,i) => {
            arr[i].imgHigh = j
          } )
          this.fileList = arr;
          console.log(this.fileList,"this.fileList")
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        this.form.data.image = '';
        this.form.data.detail_image = '';
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _params = {...this.form.data}
            let _imgarr = []
            this.fileList.forEach(i => {
              _imgarr.push(i.url)
            })
            let _high = this.fileList.map(v => { return v.imgHigh }).join(",")

            _params.status = this.form.data.status == true ? 1 : 2
            _params.is_multi = this.form.data.order_mode == 2 ? 2 : this.form.data.is_multi; //设置购买服务就不能设置多人模式
            _params.detail_image = this.form.data.order_mode == 2 ? _imgarr.join(",") : ''; // 设置购买服务可以上传详情图
            _params.high = this.fileList.length > 0 ? _high : ''; // 上传详情图，需要传详情图高度
            
            if(!!this.isChange) {
              delete _params.detail_image_arr; // 编辑时去掉该字段
              delete _params.image_str;
            }
            let apiUrl = !this.isChange ? '/admin/flex_kind/add' : '/admin/flex_kind/edit'
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      // 图片上传之前
      beforeAvatarUpload(file) {
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
          this.$message.warning('上传图片大小不能超过 20MB!');
        }
        return isLt20M;
      },
      // 上传主图
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
          this.form.data.image = res.data.all_path_url;
          this.$refs.elFormDom.clearValidate();
        })
      },
      // 图片上传成功
      handleAvatarSuccess(res, file) {
        const img = new Image();
        img.onload = () => {
          // 当图片加载完成后，获取图片的宽度和高度
          console.log('图片宽度：', img.width);
          console.log('图片高度：', img.height);
          this.form.data.high = img.height;
        };
        img.src = file.url;
        let strImg = {
          url: res.data.all_path_url,
          name: '',
          imgHigh: img.height,
        }
        this.fileList.push(strImg)
        this.$refs.elFormDom.clearValidate();
      },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制最多只能选择 9 个文件`);
      },
      handleRemove(file, fileList) {
        if(this.fileList.length > 0) {
          this.fileList.forEach((v, i) => {
            if(file.uid === v.uid) {
              this.fileList.splice(i,1)
            }
          })
        }
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisibleUrl = true;
      },
    }
  }
</script>

<style scoped>
.avatar-uploader-login .el-icon-plus {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader-login .avatar-uploader-icon{
  line-height: 100px !important;
}
.avatar-uploader-login .avatar {
  width: 100px;
  height: 100px;
}
.avatar-uploader-image .el-icon-plus {
  width: 234px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader-image .avatar {
  width: 234px;
  height: 100px;
}
.avatar-uploader-image .avatar-uploader-icon{
  line-height: 100px !important;
}
.el-upload__tip {
  color: #f56c6c;
  margin-top: 0;
}
.imgWrap ::v-deep .el-upload-list__item{
  width: 80px !important;
  height: 80px !important;
}
.btitle::before {
  content: "*";
  color: #F56C6C;
  margin-right: 4px;
}
.imgWrap ::v-deep .el-upload--picture-card {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
</style>